import styled from 'styled-components';
import React from 'react';

const Label = styled.label<
  React.LabelHTMLAttributes<HTMLLabelElement> & { capitalize?: boolean }
>`
  ${({ theme }) => theme.fontSize.M16}
  display: block;
  color: ${({ theme }) => theme.colors.GREY_DARK};
  margin-bottom: ${({ theme }) => theme.spacing.S8};

  ${({ capitalize }) => capitalize && 'text-transform: capitalize;'}
`;

export { Label };

import styled from 'styled-components';
import { Variants } from 'components/Toolkit/InfoTitle/InfoTitle';

const MetaListItem = styled.li<{
  variant: Variants;
}>`
  ${({ theme, variant }) =>
    variant === 'XS'
      ? theme.fontSize.S12
      : variant === 'SMALL'
      ? theme.fontSize.M14
      : theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  list-style: none;
  display: flex;
  align-items: center;

  &:after {
    width: ${({ theme }) => theme.spacing.S4};
    height: ${({ theme }) => theme.spacing.S4};
    background: ${({ theme }) => theme.colors.GREY_DARK};
    margin-left: ${({ theme }) => theme.spacing.S8};
    margin-right: ${({ theme }) => theme.spacing.S8};
    content: '';
    border-radius: 50%;
  }

  &:last-child {
    &:after {
      width: ${({ theme }) => theme.spacing.S4};
      content: none;
      height: 100%;
    }
  }
`;

export { MetaListItem };

import styled from 'styled-components';
import type { ReactNode } from 'react';

interface ISubText {
  children: ReactNode;
  className?: string;
}

const SubTextWrapper = styled.div`
  min-height: 16px;
`;

function SubText({ children, className }: ISubText) {
  return <SubTextWrapper className={className}>{children}</SubTextWrapper>;
}

export { SubText };

import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'components/Toolkit/Button/Link';
import { Title } from 'components/Toolkit/InfoTitle/Title/Title';
import { MetaList } from 'components/Toolkit/InfoTitle/MetaList/MetaList';

export type Variants = 'DEFAULT' | 'SMALL' | 'XS';

export interface InfoTitleProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  illustration?: string;
  icon?: React.ReactNode;
  listItems?: (string | undefined | ReactNode)[];
  className?: string;
  shouldTruncate?: boolean;
  withH1Tag?: boolean;
  minHeight?: boolean;
  variant?: Variants;
  flip?: boolean;
  linkHref?: string;
  linkText?: string;
  disabled?: boolean;
  dataTestId?: string;
}

export const InfoTitleContainer = styled.div<{
  variant: Variants;
  minHeight: boolean;
  shouldTruncate: boolean;
}>`
  ${({ variant, minHeight }) =>
    minHeight &&
    css`
      min-height: ${variant === 'SMALL'
        ? '40px'
        : variant === 'XS'
        ? '16px'
        : '48px'};
    `}
  display: flex;
  align-items: center;
  width: 100%;
  ${({ shouldTruncate }) => shouldTruncate && `min-width: 0;`};
`;

const ImageContainer = styled.div<{ variant: Variants }>`
  flex: 0 0 auto;
  margin-right: ${({ theme, variant }) =>
    variant === 'DEFAULT' ? theme.spacing.M16 : theme.spacing.S8};
  width: ${({ variant }) => (variant === 'XS' ? '20px' : '40px')};
  ${({ variant }) => variant === 'XS' && 'height: 20px'};

  img {
    width: 100%;
    height: auto;
  }
`;

export const IconContainer = styled.div<{ variant: Variants }>`
  margin-right: ${({ theme, variant }) =>
    variant === 'DEFAULT' ? theme.spacing.M16 : theme.spacing.S8};
`;

const Description = styled.p<{ variant: Variants; disabled?: boolean }>`
  display: block;
  margin: 0;
  ${({ theme, variant }) =>
    variant === 'SMALL' || variant === 'XS'
      ? theme.fontSize.B12
      : theme.fontSize.M14};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.GREY_DARK : theme.colors.GREY_DARK};
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
`;

const SLink = styled(Link)`
  ${({ theme }) => theme.fontSize.B12};
`;

function InfoTitle({
  title,
  description,
  illustration,
  icon,
  listItems,
  className,
  shouldTruncate = false,
  withH1Tag = false,
  variant = 'DEFAULT',
  minHeight = true,
  flip = false,
  linkHref,
  linkText,
  disabled,
  dataTestId,
}: InfoTitleProps) {
  return (
    <InfoTitleContainer
      className={className}
      variant={variant}
      minHeight={minHeight}
      shouldTruncate={shouldTruncate}
      data-testid={dataTestId}
    >
      {illustration && (
        <ImageContainer variant={variant}>
          <img src={illustration} alt={`${title} image`} />
        </ImageContainer>
      )}
      {icon && <IconContainer variant={variant}>{icon}</IconContainer>}
      <Content>
        <Title
          as={withH1Tag ? 'h1' : 'p'}
          shouldTruncate={shouldTruncate}
          variant={variant}
          flip={flip}
          disabled={disabled}
        >
          {title}
        </Title>
        {!!description && (
          <Description variant={variant} disabled={disabled}>
            {description}
          </Description>
        )}
        {listItems && <MetaList listItems={listItems} variant={variant} />}

        {linkHref && linkText && (
          <SLink ofType="SECONDARY" href={linkHref}>
            {linkText}
          </SLink>
        )}
      </Content>
    </InfoTitleContainer>
  );
}

export { InfoTitle };

import type { HTMLAttributes } from 'react';
import { useContext } from 'react';
import type { ReactNode } from 'react';

import styled, { ThemeContext } from 'styled-components';
import { InformationCircleOutlineIc } from '@dsch/dd-icons';

export interface HelpTextProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  useDangerouslySetInnerHtml?: boolean;
  link?: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
  min-height: 16px;
  font-size: 0;
`;

const Text = styled.p`
  flex: 1 1 auto;
  display: inline-block;
  vertical-align: middle;
  ${({ theme }) => theme.fontSize.S12};

  line-height: 16px;
`;

const Icon = styled(InformationCircleOutlineIc)`
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: middle;
  line-height: 16px;
  margin-right: 0.25em;
  ${({ theme }) => theme.fontSize.M16};
`;

const IconAndDangerouslySetInnerHtmlContainer = styled.div`
  display: flex;

  div:first-child {
    align-self: center;
    width: ${({ theme }) => theme.spacing.M16};
    margin-right: ${({ theme }) => theme.spacing.S8};
  }
`;

function HelpText({
  text,
  useDangerouslySetInnerHtml,
  link,
  ...rest
}: HelpTextProps) {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper {...rest}>
      {!useDangerouslySetInnerHtml ? (
        <>
          <Icon color={theme.colors.BLUE} width={16} height={16} />{' '}
          <Text>
            {text}
            {link}
          </Text>
        </>
      ) : (
        <>
          <IconAndDangerouslySetInnerHtmlContainer>
            <div>
              <Icon color={theme.colors.BLUE} width={16} height={16} />{' '}
            </div>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
            {link}
          </IconAndDangerouslySetInnerHtmlContainer>
        </>
      )}
    </Wrapper>
  );
}

export { HelpText };

import * as Styled from 'components/Toolkit/InfoTitle/MetaList/MetaList.styled';
import { MetaListItem } from 'components/Toolkit/InfoTitle/MetaListItem/MetaListItem';
import type { MetaListProps } from 'components/Toolkit/InfoTitle/MetaList/MetaList.typed';

const MetaList = (props: MetaListProps) => {
  const { listItems, variant = 'DEFAULT' } = props;

  return (
    <Styled.MetaList>
      {listItems.map(
        (item, index) =>
          item && (
            <MetaListItem key={index} variant={variant}>
              {item}
            </MetaListItem>
          ),
      )}
    </Styled.MetaList>
  );
};

export { MetaList };
